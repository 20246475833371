import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ContactPageTemplate from '../components/ContactPageTemplate'
import Layout from '../components/Layout'

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <ContactPageTemplate {...frontmatter} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          meta_title
          meta_description
        }
        title
        subtitle
        send_message_btn
        contacts {
          info
          email
          phone
          phone_description
          address
          image
        }
        form {
          name
          email
          phone
          message
          terms
          validation_message1
          validation_message2
          success
          success_message
        }
      }
    }
  }
`;
