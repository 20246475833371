import { keyframes } from 'styled-components'

export const slideIn = keyframes`
  0% {
    transform: translate3d(0, -60px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const slideOut = keyframes`
  100% {
    transform: translate3d(0, -60px, 0);
  }
  0% {
    transform: translate3d(0, 0, 0);
  }
`

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const fadeOut = keyframes`
  100% {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`
