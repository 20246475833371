import React, { useState, useRef } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Done } from '@styled-icons/material'
import { Close } from '@styled-icons/evil'
import { Portal } from '../../components/Common/'
import { validateEmail } from '../../utils/validation'
import Markdown from 'react-markdown'

import { ModalContainer, ModalHeader, CloseButton } from './styles'

const SEND_EMAIL_TO_SUPPORT = 'https://app.talkguest.com/Emails/rest/Email/SendEmailToSupport'
const HEADERS = {
  'Content-Type': 'application/json',
  Authorization: 'Basic dGd3ZWJzaXRlOnVsMUN0SWNXV2dvaHJ2QzZDaFdRamQ2SWJXaDczeQ==',
}

function ContactPageTemplate (props) {
  const { meta, title, subtitle, send_message_btn, contacts, form } = props
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    terms: false,
  })

  const [isModalVisible, setModalVisible] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [submitPressed, setSubmitPressed] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const ref = useRef(null)

  function handleChange (e) {
    e.persist()
    const value =
      e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value
    const name = e.target.name

    setValues(values => ({ ...values, [name]: value }))
    setSubmitError('')
  }

  function isValidForm () {
    return values.name && values.message && values.terms && validateEmail(values.email)
  }

  function handleSubmit (e) {
    e.preventDefault()
    setSubmitPressed(true)
    // don't send form if user remove 'disabled' attribute in DOM
    if (isValidForm()) {
      setSubmitting(true)
      const { name, phone, email, message } = values

      fetch(SEND_EMAIL_TO_SUPPORT, {
        method: 'POST',
        headers: HEADERS,
        body: JSON.stringify({
          From_Name: name,
          From_Email: email,
          From_PhoneNumber: phone,
          MessageContent: message,
        }),
      })
        .then(response => {
          setSubmitError('')
          setSubmitting(false)

          if (response.ok) {
            togglePortalVisibility()
          } else {
            setSubmitError('Erro ao processar solicitação')
          }
        })
        .catch(error => {
          console.log(error)
          setSubmitting(false)
          setSubmitError('Algo deu errado, tente novamente')
        })
    }
  }

  function renderValidationMessage () {
    return !isValidForm() && submitPressed
  }

  function toggleDangerClass (field) {
    return !field && submitPressed ? 'is-danger' : ''
  }

  function togglePortalVisibility () {
    if (isModalVisible) {
      ref.current.classList.add('hide')
      return setTimeout(() => setModalVisible(false), 1000)
    }
    setModalVisible(true)
  }

  return (
    <div>
      <Helmet>
        <title>{meta.meta_title}</title>
        <meta name='description' content={meta.meta_description} />
      </Helmet>
      <section className='ContactPageImageAndAddress'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-6 ColForm'>
                <div className='section FormSection'>
                  <h1 className='title'>
                    {title}
                  </h1>
                  <h2 className='subtitle'>
                    {subtitle}
                  </h2>
                  <section className='FormArea'>
                    <form name='contact' method='post' onSubmit={handleSubmit}>
                      <div className='field'>
                        <label className='label'>{form.name}</label>
                        <div className='control'>
                          <input className={`input ${toggleDangerClass(values.name)}`} type='text' placeholder={form.name} name='name' id='name' onChange={handleChange} />
                        </div>
                      </div>

                      <div className='field'>
                        <label className='label'>{form.email}</label>
                        <div className='control'>
                          <input className={`input ${toggleDangerClass(validateEmail(values.email))}`} type='email' placeholder={form.email} name='email' id='email' onChange={handleChange} />
                        </div>
                      </div>

                      <div className='field'>
                        <label className='label'>{form.phone}</label>
                        <div className='control'>
                          <input className='input' type='number' placeholder={form.phone} name='phone' id='phone' onChange={handleChange} />
                        </div>
                      </div>

                      <div className='field'>
                        <label className='label'>{form.message}</label>
                        <div className='control TextArea'>
                          <textarea className={`textarea ${toggleDangerClass(values.message)}`} placeholder={form.message} name='message' id='message' rows='6' onChange={handleChange} />
                        </div>
                      </div>
                      <div className='TermsAndConditions'>
                        <input
                          type='checkbox'
                          name='terms'
                          id='terms'
                          onChange={handleChange}
                        />
                        <label htmlFor='terms' className='TermsLabel'>{form.terms}</label>
                      </div>
                      <div className='send-message field is-grouped'>
                        <div className='control'>
                          <button className='button is-primary' type='submit' disabled={submitting}>{send_message_btn}</button>
                        </div>
                      </div>
                      {renderValidationMessage() && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: 12,
                            maxWidth: 373,
                          }}
                        >
                          <div>{form.validation_message1}</div>
                          {form.validation_message2}
                        </div>
                      )}
                      {submitError && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: 12,
                            maxWidth: 373,
                          }}
                        >
                          {submitError}
                        </div>
                      )}
                    </form>
                  </section>

                </div>
              </div>
              <div className='column is-6 ColAddress'>
                <div className='section'>
                  <h1 className='title'>
                    {title}
                  </h1>
                  <h2 className='subtitle'>
                    {subtitle}
                  </h2>
                  <div className='Col2'>
                    <p className='has-text-centered offer-image'>
                      <img alt='icon' src={contacts.image} style={{ maxWidth: contacts.imagewidth + 'px' }} />
                    </p>
                    <Markdown source={contacts.info} className='Description1' />
                    <div className='PhoneArea'>
                      <div className='icon'>
                        <img alt='icon' src='/img/headset.png' />
                      </div>
                      <div className='Phones'>
                        <div className='phone'>
                          {contacts.phone}
                        </div>
                        <div className='phone_description'>
                          {contacts.phone_description}
                        </div>
                      </div>
                    </div>
                    <div className='PhoneArea'>
                      <div className='icon'>
                        <img alt='icon' src='/img/gps.png' />
                      </div>
                      <Markdown source={contacts.address} className='Address' />
                    </div>
                    <div className='PhoneArea'>
                      <div className='icon'>
                        <img alt='icon' src='/img/mail.png' />
                      </div>
                      <div className='Email'>
                        <div className='email'>
                          {contacts.email}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isModalVisible && (
        <Portal noScrollDisabled>
          <ModalContainer ref={ref} className='modal is-active'>
            <div className='modal-background' />
            <div className='modal-wrapper'>
              <ModalHeader>
                <Done size={30} color='#14cc89' />
                <span>{form.success}</span>
                <CloseButton onClick={togglePortalVisibility}>
                  <Close size={22} />
                </CloseButton>
              </ModalHeader>
              <div className='modal-content'>
                <p>
                  {form.success_message}
                </p>
              </div>
            </div>
          </ModalContainer>
        </Portal>
      )}
    </div>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  contacts: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

export default ContactPageTemplate
