import React from 'react'
import styled, { css } from 'styled-components'
import { Search as SearchIcon } from '@styled-icons/material'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Text = styled.span`
  color: #ccc;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0;
    background-color: blue;
    transition: opacity 0.3s ease;
  }
  &:hover:before {
    opacity: 1;
  }
`

function IconButton({ icon, text, onClick, cssStyle, textStyle, isHoverUnderLine, type }) {
  return (
    <Wrapper onClick={onClick} css={cssStyle} type={type}>
      <Text css={textStyle} isHoverUnderLine={isHoverUnderLine}>{text}</Text>
      <SearchIcon />
    </Wrapper>
  )
}

export default React.memo(IconButton)
