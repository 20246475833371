import { useEffect } from 'react'
import ReactDOM from 'react-dom'

export default function Portal(props) {
  let lastScrollPosition

  function disableScroll() {
    lastScrollPosition = window.scrollY
    window.addEventListener('scroll', preventScrolling)
  }

  function enableScroll() {
    window.removeEventListener('scroll', preventScrolling)
  }

  function preventScrolling() {
    window.scrollTo(0, lastScrollPosition)
  }

  useEffect(() => {
    if (!props.noScrollDisabled) {
      disableScroll()
      return () => enableScroll()
    }
  }, [])

  const el = props.reference || document.querySelector('#main')
  return ReactDOM.createPortal(props.children, el)
}
