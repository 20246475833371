import styled from 'styled-components'

import { fadeIn, fadeOut } from '../../utils/animations'

export const ModalContainer = styled.div`
  backdrop-filter: blur(2px);
  animation: .4s ${fadeIn} ease-in-out;
  box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), 0 -18px 60px -10px rgba(0,0,0,.025);

  &.hide {
    animation: .4s ${fadeOut} ease-in-out forwards;
  }

  .modal-background {
    background-color: rgba(10, 10, 10, 0.35);
  }

  .modal-wrapper {
    position: relative;
    background-color: #fff;
    box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), 0 -18px 60px -10px rgba(0,0,0,.025);
    display: flex;
    flex-direction: column;
    max-width: 350px;
    border-radius: 10px;
    position: relative;
    margin: 0 15px;
    padding: 1.5rem 1rem 2rem;
  }

  .modal-content {
    max-width: 100%;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    margin-right: 10px;
  }

  span {
    font-size: 25px;
    font-weight: 700;
    flex-grow: 1;
  }
`

export const CloseButton = styled.button`
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  width: 34px;
  height: 34px;
  transition: all .25s ease;

  svg {
    margin: 0;
  }

  &:hover {
    background-color: #efefef;
  }
`
